import React from 'react';

const LoaderWithText = ({ text, isRounded }) => {
  return (
    <div className={`${isRounded ? 'rounded-t-[25px]' : ''} new_loading_container `}>
      <svg className="svg" viewBox="25 25 50 50">
        <circle className="circle" r="20" cy="50" cx="50" />
      </svg>
      <div className="new_loading_text">{text}</div>
    </div>
  );
}

export default LoaderWithText;
