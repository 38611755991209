export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const formatFidelityPointsFromUser = (user) => {
  // On s'assure que le user.fidelity n'est pas undefined
  if (!user?.fidelity) {
    return 0;
  }

  return formatFidelityPoints(user.fidelity);
};

export const formatFidelityPoints = (fidelity) => {
  const fidelityMultiplicator = parseFloat(process.env.REACT_APP_FIDELITY_MULTIPLICATOR);

  // On s'assure qu'on ne divisera pas par 0
  if (!fidelityMultiplicator) {
    return 0;
  }

  const decimalPrice = fidelity / fidelityMultiplicator;
  if (isNaN(decimalPrice)) {
    return 0;
  }
  return Math.floor(decimalPrice);
};

export const showFidelityPointToEuro = (user) => {
  const conversionRate = parseFloat(process.env.REACT_APP_CONVERSION_POINT_TO_EURO);

  // On s'assure que le user.fidelity n'est pas undefined et que le conversionRate est bien défini
  if (!user?.fidelity || !conversionRate) {
    return 0;
  }

  return formatPrice(formatFidelityPointsFromUser(user) * conversionRate);
};

export const productPrice = (product) => {

  return product.promoPrice && product.promoPrice > 0 ? product.promoPrice : product.price;

};

export const cartPrice = (cartItems) => {
  return cartItems.promoPrice && cartItems.promoPrice > 0 ? cartItems.promoPrice : cartItems.itemsPrice;
};

export const formatPrice = (price) => {
  let currentPrice = price;

  if (price === undefined) {
    currentPrice = 0;
  } else if (typeof price === 'string') {
    try {
      currentPrice = parseFloat(price);
    } catch(e) {
      console.log('Error while parsing price', e);
      currentPrice = 0;
    }
  }

  return currentPrice.toFixed(2).replace('.', ',');
};
export const formatPriceEuroToPikkoPoints = (price) => {
  const conversionRate = parseFloat(process.env.REACT_APP_CONVERSION_POINT_TO_EURO);

  // On s'assure que le user.fidelity n'est pas undefined et que le conversionRate est bien défini
  if (!conversionRate || typeof price !== 'number') {
    return 0;
  }

  return Math.floor(price / conversionRate);
}
export const formatPriceWithEuro = (price) => formatPrice(price) + '€';

export const getUserEmail = (user) => user?.email && user.email.length > 0 ? user.email : '';
export const getUserEdenredEmail = (user) => user?.edenred?.username && user?.edenred?.username.length > 0 ? user?.edenred?.username : '';


export const displayCouponNameProperly = (promoItem) => { // TODO : Do a switch
  if (promoItem.type === 'gift' || promoItem.type === 'pikkoday') {
    return promoItem.name.slice(5).replace('e', '€');
  }
  if (promoItem.type === 'pre-inscription' || promoItem.type === 'reward' || promoItem.type === 'purchasePromo' || promoItem.type === 'welcome' || promoItem.type === 'business') {
    return `${promoItem.price}€`;
  }
  return '';
};
