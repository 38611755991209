import React, { useEffect, useState } from "react";
import arrow from "../styles/img/arrow/arrow.svg";
import logopikkopay from "../styles/img/logo_pikkopay2.svg";
import addToHome from "../styles/img/addToHome.svg";
import shareicontuto from "../styles/img/shareicon-tuto.svg";
import pikkophone from "../styles/img/pikkophone.svg";
import step1 from "../styles/img/account/step1.png";
import step2 from "../styles/img/account/step2.png";
import chrome_step1 from "../styles/img/account/chrome_step1.png";
import chrome_step2 from "../styles/img/account/chrome_step2.png";
import step3 from "../styles/img/account/step3.png";
import pikkoPhone from "../styles/img/installPwaPhone.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import logoBrand from "../styles/img/logo-tuto.svg";
import tuto from "../styles/img/tuto.gif";

import '@splidejs/react-splide/css';
const InstallPWA = ({isAnim}) => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    try{
        promptInstall.prompt();
    } catch(e){
        setIsPopupVisible(true)
    }
  };

  return (
    <>
    <div className={'install-pwa' + (isAnim ? ' tremble' : '')}
      onClick={onClick}
      >


      <span className="install-pwa-text" style={{fontFamily: "geomanistmedium"}}>Télécharge l'app pour aller encore plus vite</span>
      <img src={pikkoPhone} className="install-pwa-phone" alt="" />


      </div>

        {isPopupVisible && <div className="tuto-pwa-container">
            <div className="logo-tuto">
               <div className="logo-brand">
                <img src={logoBrand} alt="logo-brand" />
              </div>
            </div>
            Installer PikkoPay
            <div className="tuto-pwa">
              <img src={tuto} alt="" />
            </div>
            <div className="text-tuto-pwa" onClick={() => setIsPopupVisible(false)}>
                C'est compris
            </div>
        </div>  }  
           

    </>
  );
};

export default InstallPWA;
