import React from 'react';
import generatePDF from './generatePdf';

const InvoiceButton = ({ invoiceData }) => {
  const handleDownload = () => generatePDF(invoiceData);

  return (
    <button
    className='payer p-6 w-[92%] text-[1.7rem] geomanistBold'
     onClick={handleDownload}>Télécharger la Facture</button>
  );
};

export default InvoiceButton;
