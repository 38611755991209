export const ORDER_ID = ":orderId";
export const BRAND_ID = ":brandId";
export const USER_FID_CARD_ID = ":fidCardId";

export const ROOT = "/";
export const STORE_LIST = "/store-list";
export const HOME = "/home";
export const PRE = "/pre";
export const SCAN = "/scan";
export const INVITATION = "/invitation";
export const PRODUCT = "/product/:id";
export const EDENRED = "/edenred";
export const CHECK = `/check/${ORDER_ID}`;
export const ORDER_SUCCESS = `/ordersuccess/${ORDER_ID}`;
export const ORDER = `/order/${ORDER_ID}`;
export const SCAN_CHECK = "/ScanCheck"; // reco -> /scan-check
export const CART = "/cart";
export const POPUP_PAYMENT = "/popupPayment"; // reco -> /popup-payment
export const GIFT = "/gift"; // TODO : vérifier l'usage  de cette route
export const REWARD = "/reward";
export const PROMO = "/promo";
export const CONVERSION = `${PROMO}/conversion`;
export const BRAND = "/brands";
export const BRAND_DETAIL = `${BRAND}/${BRAND_ID}`;

// ACCOUNT
export const ACCOUNT = "/account";
export const RESETPASSWORD = `${ACCOUNT}/resetpassword`;
export const TICKETS = `${ACCOUNT}/tickets`;
export const COUPONS = `${ACCOUNT}/coupons`;
export const RECOMPENSES = `${ACCOUNT}/recompenses`;
export const HELP = `${ACCOUNT}/help`;
export const WALLET = `${ACCOUNT}/wallet`;
export const ADD_PAYMENT = `${WALLET}/addPaymentScreen`;
export const ADD_CB = `${ADD_PAYMENT}/addCb`;
export const SET_ACCOUNT = `${ACCOUNT}/setAccount`;

//FID
export const LOGIN_FID_CARD = `${ACCOUNT}/login/fid/card`;
export const LOGIN_FID_SCAN = `${ACCOUNT}/login/fid/scan`;
export const FIDLOGIN = `${ACCOUNT}/fid/login`;
export const USER_FID_CARD = "/user/fid/card";
export const USER_FID_CARD_DETAIL = `${USER_FID_CARD}/detail`;
export const ADD_FID_CARD = `${ACCOUNT}/add/fid/card`;
export const ADD_FID_SCAN = `${ACCOUNT}/add/fid/scan`;
export const NUMBER_SCREEN = `${ACCOUNT}/number`;
