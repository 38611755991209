export const vidercache = async () => {
    // Efface dans le indexDB
  console.log('OOOO')
     window.indexedDB.deleteDatabase('/scandit_sync_folder_preload');
     window.indexedDB.deleteDatabase('/scandit_sync_folder')
     localStorage.removeItem('scandit-device-id')
     document.cookie.split(";").forEach((c) => {
       document.cookie = c.trim().startsWith('_') ? c : c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
   });
    sessionStorage.clear();

     caches.open('my-cache').then(function(cache) {

       cache.keys().then(function(keys) {
         keys.forEach(function(request) {
           cache.delete(request);

         });
       }).then(function() {
         console.log('Cache "my-ache" vidé avec succès.');
         window.location.reload();
       });
     });}