import React from 'react';
import CustomLoader, { LoaderColor, LoaderSize } from '../loader/CustomLoader';

const PrimaryButton = ({onClick, label, icon, disabled, isLoading}) => {
    return (
      <button
        className={`primary-button ${disabled || isLoading ? 'disabled' : ''}`}
        disabled={disabled || isLoading}
        onClick={onClick}
      >
        {isLoading
          ? (<CustomLoader loaderSize={LoaderSize.SMALL} loaderColor={LoaderColor.GREY} />)
          : (
            <>
              {label && <span>{label}</span>}
              {icon && <img className="icon-button" src={icon} alt="" />}
            </>
          )
        }
      </button>
    );
};

export default PrimaryButton;
