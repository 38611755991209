// import data from './data'
import thunk from "redux-thunk";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import {
  detailsProductReducer,
  productListReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  orderComReducer,
  orderDetailsReducer,
} from "./reducers/orderReducers";
import { idReducer } from "./reducers/idReducers";
import { storeReducer } from "./reducers/storeReducers";
import { payMethodReducer } from "./reducers/payMethodReducers";
import { addUserReducer } from "./reducers/userReducers";
import { edenredReducer } from "./reducers/edenredReducers";
import { conecsReducer } from "./reducers/conecsReducers";
import { cbReducers } from "./reducers/cbReducers";
import { scanReducers } from "./reducers/scanReducers";

const initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
  },
  id: localStorage.getItem("id") ? JSON.parse(localStorage.getItem("id")) : {},
  productList: {
    products:
      localStorage.getItem("products") === "undefined"
        ? []
        : JSON.parse(localStorage.getItem("products")),
    // products: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
  },
  orderDetails: localStorage.getItem("orderDetails")
    ? JSON.parse(localStorage.getItem("orderDetails"))
    : [],
  store: localStorage.getItem("store")
    ? JSON.parse(localStorage.getItem("store"))
    : {},

  payMethod: localStorage.getItem("payMethod")
    ? JSON.parse(localStorage.getItem("payMethod"))
    : {},

  user: localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem("user"))
  : {
    creditCard: {},
    conecs: {},
    edenred: {},
    usedPromo: [], 
  },

  edenred: localStorage.getItem("Edenred")
  ? JSON.parse(localStorage.getItem("Edenred"))
  : {},

  conecs: localStorage.getItem("Conecs")
  ? JSON.parse(localStorage.getItem("Conecs"))
  : {},

  creditCard: localStorage.getItem("creditCard")
  ? JSON.parse(localStorage.getItem("creditCard"))
  : {},

  scanner: localStorage.getItem("scanner")
  ? JSON.parse(localStorage.getItem("scanner"))
  : {},
 
};
// const reducer = (state, action)=>{
//     // State Redux Browser: on voit la liste des produits car on le return ici
//     return{products: data.products}
// }
const reducer = combineReducers({
  orderDetails: orderDetailsReducer,
  cart: cartReducer,
  productList: productListReducer,
  productDetails: detailsProductReducer,
  orderCom: orderComReducer,
  id: idReducer,
  store: storeReducer,
  payMethod: payMethodReducer,
  user: addUserReducer,
  edenred: edenredReducer,
  conecs: conecsReducer,
  creditCard: cbReducers,
  scanner: scanReducers
});

const composeEnhance = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhance(applyMiddleware(thunk))
);
// DISPATCH USER INITIAL PROMO STATE

export default store;
